// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
  background-color: #000000;
}

.my-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}
.category {
  position: relative;
  font-size: 45px;
  text-align: center;
  font-family: 'Staatliches' ;
  color: red;
  text-shadow: yellow 1px 1px 2px;
}  

.my-movies {
  flex: 1 1 200px; 
  margin: 15px;
}
.my-movies li{
  list-style: none;
} 
.my-movies img {
  width: 100%;
  height: auto;
  box-shadow: 0px 0px 15px 3px blue;
  transition: box-shadow 0.5s ease;
}
.my-movies img:hover{
  box-shadow: 0px 0px 7px 5px red;
}

@media (max-width: 767px) {
  .category {
    font-size: 35px;
  }
  .my-movies img {
    box-shadow: 0px 0px 10px 5px blue;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/displayMovies.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,6BAA6B;EAC7B,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,2BAA2B;EAC3B,UAAU;EACV,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iCAAiC;EACjC,gCAAgC;AAClC;AACA;EACE,+BAA+B;AACjC;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,iCAAiC;EACnC;AACF","sourcesContent":[".body {\n  background-color: #000000;\n}\n\n.my-container {\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: space-around;\n  align-items: center;\n  padding-left: 5px;\n  padding-right: 5px;\n}\n.category {\n  position: relative;\n  font-size: 45px;\n  text-align: center;\n  font-family: 'Staatliches' ;\n  color: red;\n  text-shadow: yellow 1px 1px 2px;\n}  \n\n.my-movies {\n  flex: 1 1 200px; \n  margin: 15px;\n}\n.my-movies li{\n  list-style: none;\n} \n.my-movies img {\n  width: 100%;\n  height: auto;\n  box-shadow: 0px 0px 15px 3px blue;\n  transition: box-shadow 0.5s ease;\n}\n.my-movies img:hover{\n  box-shadow: 0px 0px 7px 5px red;\n}\n\n@media (max-width: 767px) {\n  .category {\n    font-size: 35px;\n  }\n  .my-movies img {\n    box-shadow: 0px 0px 10px 5px blue;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
